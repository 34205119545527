<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 mt30 wordSetContent">
                <div class="wordContentTitle mb50">在职直接从事研发活动人员名单</div>
                <div class="flex-a-b-c mb20">
                    <div class="flex-c-c">
                        <div>纳税人名称 (公章)：</div>
                        <div>{{ checkedCompany.companyName }}</div>
                    </div>
                    <div class="flex-c-c">
                        <div>纳税人识别号：</div>
                        <div>
                            {{ checkedCompany.creditCode }}
                        </div>
                    </div>
                </div>
                <div class="flex-a-b-c mb20">
                    <div class="flex-c-c">
                        <div>{{ checkedProject.endYear }}</div>
                        <div>年度</div>
                    </div>
                    <div>
                        金额单位：元（列至角分）
                    </div>
                </div>
                <div class="flex-a-b-c mb20">
                    <div class="flex-c-c">
                        <div>项目名称：</div>
                        <div>{{ projectData.name }}</div>
                    </div>
                </div>
            </div>
            <table border="1" cellspacing="0" class="tableBorder institutionTable">
                <thead>
                    <tr class="h60">
                        <td class="textc itemTitle fs8 w30">序号</td>
                        <td class="textc itemTitle fs8 w80">姓名</td>
                        <td class="textc itemTitle fs8 w50">性别</td>
                        <td class="textc itemTitle fs8 w100">出生年月</td>
                        <td class="textc itemTitle fs8 w100">职称</td>
                        <td class="textc itemTitle fs8 w60">学历</td>
                        <td class="textc itemTitle fs8 w100">专业</td>
                        <td class="textc itemTitle fs8">入职时间（年/月/日）</td>
                        <td class="textc itemTitle fs8 w100">所在工作部门</td>
                        <td class="textc itemTitle fs8 w100">分工</td>
                        <td class="textc itemTitle fs8">本年度本项目工资薪金</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="h60" v-for="(row, index) in userList" :key="index">
                        <td class="textc itemContent">{{ index+1 }}</td>
                        <td class="itemContent textc">
                            {{row.userName}}
                        </td>
                        <td class="textc itemContent">
                            {{row.sex}}
                        </td>
                        <td class="itemContent textc">
                            {{row.birth}}
                        </td>
                        <td class="itemContent textc">
                            {{row.job}}
                        </td>
                        <td class="itemContent textc">
                            {{row.education}}
                        </td>
                        <td class="textc itemContent">
                            {{row.major}}
                        </td>
                        <td class="itemContent textc">
                            {{row.inTime}}
                        </td>
                        <td class="itemContent textc">
                            {{row.department}}
                        </td>
                        <td class="textc itemContent">
                            {{row.work}}
                        </td>
                        <td class="textc itemContent posre">
                            {{row.money}}
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle">
                            合计
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc fs8">
                            -
                        </td>
                        <td class="textc">
                            {{ sumNumber }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        modelData: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            userList: [
                { num: 1, name: "", sex: "", name: "", sex: "", name: "", sex: "", name: "", sex: "", sex: "" }
            ],
        }
    },
    computed: {
        sumNumber() {
            let sum = 0;
            if (this.userList && this.userList.length > 0) {
                for (let row of this.userList) {
                    if (row.money) {
                        sum += Number(row.money);
                    }
                }
            }
            return sum.toFixed(2);
        }
    },
    watch: {
        //监听填写数据
        modelData: {
            handler(data) {
                this.createData()
                if (data && JSON.stringify(data) != '{}') {
                    this.userList=[];
                    for(let row of data){
                        row.inTime=row.inTime?row.inTime.slice(0,10):"";
                        row.birth=row.birth?row.birth.slice(0,10):"";
                        this.userList.push(row);
                    }
                }
            },
            deep: true
        },
    },
    methods: {
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                endYear: checkedProject.endDay ? checkedProject.endDay.slice(0, 4) : "",
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                //转中文
                projectDayText: this.convertChinese(checkedProject.projectDay),
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
            };
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
    }
}
</script>
<style lang="scss" scoped>
.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px 15px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 17px;
    letter-spacing: 2px;

}

.tool {
    display: block;
    position: absolute;
    top: 50%;
    right: -51px;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    background: #f1f2f4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

    .eicon {
        font-size: 18px;
        color: #666;
        cursor: pointer;
        height: 25px;
        width: 25px;
        text-align: center;
        line-height: 25px;
    }

    .el-icon-delete {
        color: #F56C6C;
    }
}

.itemTitle {
    font-size: 17px;
    height: 100%;
    overflow: hidden;
    padding: 0 5px;
}

.itemContent {
    min-height: 40px;

    div {
        font-size: 17px;
    }


}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 17px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 17px;
            }
        }



        /deep/.el-range-editor--small.el-input__inner {
            height: 58px;
        }

        /deep/.el-range-separator {
            padding: 0;
            line-height: 50px;
            font-size: 20px;
            width: 20px;
        }

        /deep/.el-input__inner {
            padding-right: 0;
        }

        /deep/.el-range__close-icon {
            font-size: 20px;
            line-height: 53px;
        }

        .allShow {
            width: 100%;
            height: 40px;
            border: none;
        }

        /deep/.allShow input {
            width: 100%;
            font-size: 17px !important;
        }

        /deep/input {
            height: 39px;
            line-height: 39px;
            font-size: 17px !important;
        }

        /deep/.el-input__icon {
            line-height: 39px;
            font-size: 17px !important;
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;
        }

        .textareaContent {
            text-indent: 45px;
            text-align: justify;
            line-height: 40px;
        }
    }
}
</style>