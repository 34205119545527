<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 mt30 wordSetContent">
                <div class="wordContentTitle">从事研发活动的人员和用于研发活动的仪器、</div>
                <div class="wordContentTitle">设备、无形资产的费用分配说明</div>
                <div class="fwbold">珠海市税务局：</div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我司{{ checkedProject.endYear
                    }}年度研发项目中从事研发活动的人员和直接材料、用于研发活动的仪器、设备、无形资产的费用、其他费用的分配说明如下：
                </div>
                <div class="textIndent40">
                    <div>（一）人员人工</div>
                    <div>包括企业科技人员的工资薪金、基本养老保险费、基本医疗保险费、失业保险费、工伤保险费、生育保险费和住房公积金，以及外聘科技人员的劳务费用。</div>
                    <div>本公司科技人员是指直接从事研究开发和相关技术创新活动,以及专门从事上述活动的管理和提供直接技术服务的,累计实际工作时间在183天以上的人员,包括在职、兼职和临时聘用人员。</div>
                    <div>分配方法：人员人工在各个研究开发项目之间分配采用直接投入工时比例法。</div>
                    <div>（二）直接投入</div>
                    <div>
                        直接投入费用是指企业为实施研究开发活动而实际发生的相关支出。包括：直接消耗的材料、燃料和动力费用；用于中间试验和产品试制的模具、工艺装备开发及制造费，不构成固定资产的样品、样机及一般测试手段购置费，试制产品的检验费；用于研究开发活动的仪器、设备的运行维护、调整、检验、检测、维修等费用，以及通过经营租赁方式租入的用于研发活动的固定资产租赁费。
                    </div>
                    <div>本公司未发生此项费用。</div>
                    <div>（三）折旧费用与长期待摊费用摊销</div>
                    <div>折旧费用是指用于办公设备、实验室设备和电路安装的折旧费。</div>
                    <div>长期待摊费用是指研发设施的改建、改装、装修和修理过程中发生的长期待摊费用。</div>
                </div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable ml100" style="width:750px">
                    <tr class="h40">
                        <td class="textc itemTitle fs9 w120">类别</td>
                        <td class="textc itemTitle fs9 w100">折旧年限（年）</td>
                        <td class="textc itemTitle fs9 w100">残值率（%）</td>
                        <td class="textc itemTitle fs9 w100">年折旧率（%）</td>
                    </tr>
                    <tr class="h40">
                        <td class="itemTitle fs9 w120 pl10">电子设备</td>
                        <td class="textc itemTitle fs9">7</td>
                        <td class="textc itemTitle fs9">3</td>
                        <td class="textc itemTitle fs9">13.86</td>
                    </tr>
                    <tr class="h40">
                        <td class="itemTitle fs9 w120 pl10">其中：计算机</td>
                        <td class="textc itemTitle fs9">5</td>
                        <td class="textc itemTitle fs9">3</td>
                        <td class="textc itemTitle fs9">19.40</td>
                    </tr>
                    <tr class="h40">
                        <td class="itemTitle fs9 w120 pl10">办公设备及其他</td>
                        <td class="textc itemTitle fs9">7</td>
                        <td class="textc itemTitle fs9">3</td>
                        <td class="textc itemTitle fs9">13.86</td>
                    </tr>
                </table>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ checkedProject.endYear
                    }}年主要分摊的为研发用的服务器及台式机电脑、笔记本电脑。分配方法：共用研究开发设备折旧费用按工作量法进行分配。研究开发活动发生折旧费用在各个研究开发项目之间分配采用工作量法。
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 mt80 wordSetContent">
                <div class="textIndent40">
                    <div>（四）无形资产摊销费用</div>
                    <div>无形资产摊销费用是指用于研究开发活动的软件、知识产权、非专利技术（专有技术、许可证、设计和计算方法等）的摊销费用。</div>
                    <div>分配方法：以项目实际发生时的记录为分配依据。</div>
                    <div>（五）设计费用</div>
                    <div>设计费用是指为新产品和新工艺进行构思、开发和制造，进行工序、技术规范、规程制定、操作特性方面的设计等发生的费用。包括为获得创新性、创意性、突破性产品进行的创意设计活动发生的相关费用。
                    </div>
                    <div>本公司未发生此项费用。</div>
                    <div>（六）装备调试费用与试验费用</div>
                    <div>装备调试费用是指工装准备过程中研究开发活动所发生的费用，包括研制特殊、专用的生产机器，改变生产和质量控制程序，或制定新方法及标准等活动所发生的费用。</div>
                    <div>为大规模批量化和商业化生产所进行的常规性工装准备和工业工程发生的费用不能计入归集范围。</div>
                    <div>试验费用包括新药研制的临床试验费、勘探开发技术的现场试验费、田间试验费等。</div>
                    <div>本公司未发生此项费用。</div>
                    <div>（七）其他费用</div>
                    <div>
                        指与研发活动直接相关的其他费用，如技术图书资料费、资料翻译费、专家咨询费、高新科技研发保险费，研发成果的检索、分析、评议、论证、鉴定、评审、评估、验收费用，知识产权的申请费、注册费、代理费，差旅费、会议费，职工福利费、补充养老保险费、补充医疗保险费，此部分控制在10%以内。
                    </div>
                    <div>分配方法：以项目实际发生时的记录为分配依据。</div>
                    <el-input v-model="formData.allocation" class="textareaInput" type="textarea" maxlength="1000"
                        show-word-limit :rows="10" placeholder="分配依据"></el-input>
                    <div>特此说明。</div>
                </div>
                <div class="mt100">
                    <div class="zdhq text-align-right">{{ checkedCompany.companyName }}</div>
                    <!-- <div class="flex-a-c justify-content-end" v-if="!formData.meetingTime">
                        <div>年</div>
                        <div class="ml30">月</div>
                        <div class="ml30">日</div>
                    </div>
                    <div v-if="formData.meetingTime" class="text-align-right">{{ convertChinese(formData.meetingTime) }}
                    </div> -->
                    <div class="text-align-right" style="margin-right:-30px;">
                        <el-date-picker class="wordInput w210" value-format="yyyy-MM-dd" format="yyyy年MM月dd日"
                            v-model="formData.fileDay" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        modelData: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            formData: {
                fileDay: "",
                allocation: "",
            },
        }
    },
    watch: {
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = []
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                } else {
                    this.formData = {
                        fileDay: "",
                        allocation: "（八）委外开发费用\n",
                    };
                    this.formData.allocation += "    本公司未发生此项费用。\n";
                    this.formData.allocation += "    人员人工费用0元，直接投入费0元，用折旧费用0元，无形资产摊销0元，新产品设计费0元，其他相关费用0元。";
                    this.createData();
                }
            },
            deep: true
        },
    },
    methods: {
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                endYear: checkedProject.endDay ? checkedProject.endDay.slice(0, 4) : "",
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                //转中文
                projectDayText: this.convertChinese(checkedProject.projectDay),
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
            };
            let n1 = 0, n2 = 0, n3 = 0, n4 = 0, n5 = 0, n6 = 0, n7 = 0;
            if (checkedProject.countDataList && checkedProject.countDataList.length > 0) {
                let datas = {};
                for (let row of checkedProject.countDataList) {
                    datas[row.code] = row;
                }
                n1 = datas[1] ? datas[1].money : 0;
                n2 = datas[2] ? datas[2].money : 0;
                n3 = datas[3] ? datas[3].money : 0;
                n4 = datas[4] ? datas[4].money : 0;
                n5 = datas[5] ? datas[5].money : 0;
                n6 = datas[6] ? datas[6].money : 0;
                n7 = datas[7] ? datas[7].money : 0;
            }
            this.formData.fileDay = checkedProject.endDay;
            this.formData.allocation = `（八）委外开发费用\n`;
            this.formData.allocation += `    指企业委托境内其他企业、大学、研究机构、转制院所、技术专业服务机构和境外机构进行研究开发活动所发生的费用(项目成果为企业拥有,且与企业的主要经营业务紧密相关)。委托外部研究开发费用的发生金额应按照独立交易原则确定;认定过程中,按照委托外部研究开发费用发生额的 80%计入研发费用总额。\n`;
            if (!n7 || n7 == 0) {
                this.formData.allocation += `    本公司未发生此项费用。\n\n`
            }
            this.formData.allocation += `    人员人工费用${n1}元，直接投入费${n2}元，用折旧费用${n3}元，无形资产摊销${n4}元，新产品设计费${n5}元，其他相关费用${n6}元，内部研究开发投入额小计${(n1 + n2 + n3 + n4 + n5 + n6).toFixed(2)}元，委托外部研究开发投入额小计${n7}元，项目${this.checkedProject.year}年研发投入（内、外部）总合计${(n1 + n2 + n3 + n4 + n5 + n6 + n7).toFixed(2)}元。`;
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
    }
}
</script>
<style lang="scss">
.showModelContent .textareaInput textarea {
    text-indent: 40px !important;
}
</style>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 22px;
    letter-spacing: 2px;

}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 20px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 20px;
            }
        }

        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .childTable {
                    display: flex;
                    align-items: center;
                }

                .tool {
                    display: block;
                    position: absolute;
                    right: -61px;
                    width: 60px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 20px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 40px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }


                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        /deep/.el-range-editor--small.el-input__inner {
            height: 58px;
        }

        /deep/.el-range-separator {
            padding: 0;
            line-height: 50px;
            font-size: 20px;
            width: 20px;
        }

        /deep/.el-input__inner {
            padding-right: 0;
        }

        /deep/.el-range__close-icon {
            font-size: 20px;
            line-height: 53px;
        }

        .allShow {
            width: 100%;
            height: 40px;
            border: none;
        }

        /deep/.allShow input {
            width: 100%;
            font-size: 20px !important;
        }

        /deep/input {
            height: 39px;
            line-height: 39px;
            font-size: 20px !important;
        }

        /deep/.el-input__icon {
            line-height: 39px;
            font-size: 20px !important;
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;
        }

        .textareaContent {
            text-indent: 45px;
            text-align: justify;
            line-height: 40px;
        }
    }
}
</style>